import { FormField, Input } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';

export const LongNameEntityIdField = (
  props: FieldProps<string, true>
): ReactElement => {
  const [valid, setValid] = useState(true);
  useEffect(
    () =>
      setValid(
        /^[a-zA-Z0-9_-]{1,255}$/.test(props.value) ||
          !(props.required || props.value)
      ),
    [props.value, props.required]
  );
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField label='Long name entity ID'>
      {typeof props.old === 'string' && props.old !== props.value && (
        <Input disabled={true} value={props.old} />
      )}
      <Input
        disabled={props.disabled}
        value={props.value}
        invalid={!valid}
        onChange={({ detail: { value } }) => {
          props.onChange?.(value);
        }}
        placeholder='e.g. VPClong'
      />
    </FormField>
  );
};
