import {
  Offering,
  OfferingReview,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';

export const fieldTitles: Partial<
  Record<keyof (Offering & OfferingReview), string>
> = {
  offeringId: 'ID',
  offeringName: 'Name',
  offeringStatus: 'Status',
  offeringReviewId: 'Draft ID',
  offeringReviewStatus: 'Draft status',
  regions: 'Regions',
  reviewedBy: 'Reviewed by',
  ripServiceId: 'RIP service ID',
  resourceExamples: 'Resource examples',
  prefixRequired: 'Prefix required',
  locGuidanceRequired: 'Sent for localization',
  approvedBy: 'Approved by',
  createdAt: 'Created at',
  modifiedAt: 'Modified at',
  modifiedBy: 'Modified by',
  marketingCategories: 'Marketing categories',
  doNotUseName: 'Do not use name',
  deprecatedName: 'Deprecated name',
  docsCategories: 'Docs categories',
  shortDescription: 'Short description',
  extraLongName: 'Extra long name',
  extraShortName: 'Extra short name',
  shortName: 'Short name',
  launchStatus: 'Launch status',
  locSubscribersList: 'Loc subscribers list',
  longName: 'Long name',
  localizationNotes: 'Localization notes',
  localizationWords: 'Localization words',
  localizationGuidance: 'Localization guidance',
  userPermissions: 'User permissions',
  generalNotes: 'General notes',
  brandPrefix: 'Brand prefix',
  createdBy: 'Created by',
  type: 'Type',
  offeringReviewType: 'Offering review type',
  reviewerNotes: 'Reviewer notes',
  // dataSource: 'Data source',
};
