import {
  Header,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react/polaris';
import { makeOfferingOrDraftCell } from './makeOfferingOrDraftCell';
import { fieldTitles } from './f2/fieldTitles';
import { ReactElement, useEffect, useState } from 'react';
import {
  Prefs,
  OfferingListCollectionPreferences,
} from './OfferingListCollectionPreferences';
import { offeringPropList } from './RowPropTypes';
import { Pagination } from '@amzn/awsui-components-react';

// import mockData from './example-data/offerings.json';
import { useAuthState } from '../authentication';
import {
  ListOfferingsCommand,
  Offering,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../client';

import { ExportOfferingList } from './ExportOfferingList';
export const OfferingList = (): ReactElement => {
  const { token } = useAuthState();
  const [sortingDescending, setSortingDescending] = useState(false);

  const [sortColumn, setSortColumn] = useState<
    TableProps.SortingColumn<Offering>
  >({
    sortingField: 'offeringName',
  });
  const [rows, setRows] = useState<Offering[]>([]);
  const [filteredRows, setFilteredRows] = useState<Offering[]>([]);
  const [queriedMocks, setQueriedMocks] = useState(false);
  const orderRows = () => {
    const field = (sortColumn.sortingField ?? 'offeringName') as keyof Offering;
    return [...filteredRows].sort(
      sortingDescending
        ? (a, b) => String(b[field]).localeCompare(String(a[field]))
        : (a, b) => String(a[field]).localeCompare(String(b[field]))
    );
  };
  const [orderedRows, setOrderedRows] = useState(orderRows());
  const [filteringText, setFilteringText] = useState('');
  const getPage = (page: number, size: number) =>
    orderedRows.slice((page - 1) * size, page * size);
  const [items, setItems] = useState<Offering[]>([]);
  const OFFERINGS_COLUMNS = 'offeringsColumns';
  const OFFERINGS_PAGE_SIZE = 'offeringsPageSize';
  // const VISIBLE_COLUMNS: (keyof Offering | Control)[] = [
  const VISIBLE_COLUMNS: (keyof Offering)[] = [
    'offeringName',
    // 'serviceName',
    // 'isPrefixRequired',
    'brandPrefix',
    // 'extraLongName',
    // 'shortName',
    // 'extraShortName',
    // 'doNotUseName',
    // 'generalNotes',
    // 'resourceExamples',
    'localizationGuidance',
    // 'localizationNotes',
    // 'docsCategory',
    'type',
    // 'deprecatedName',
    // "locale",
    // 'offeringStatus',
  ];
  const [preferences, setPreferences] = useState<Prefs>({
    pageSize: 20,
    wrapLines: true,
    visibleContent: VISIBLE_COLUMNS,
  });

  // Set initial visible columns from localStorage if it exists
  useEffect(() => {
    if (localStorage.getItem(OFFERINGS_COLUMNS)) {
      const visibleContent = localStorage
        .getItem(OFFERINGS_COLUMNS)
        ?.split(',');
      if (visibleContent)
        setPreferences({ ...preferences, visibleContent: visibleContent });
    }
    if (localStorage.getItem(OFFERINGS_PAGE_SIZE)) {
      const pageSize = localStorage.getItem(OFFERINGS_PAGE_SIZE);
      if (pageSize)
        setPreferences({ ...preferences, pageSize: parseInt(pageSize) });
    }
  }, []);

  // Save column preferences to localStorage
  useEffect(() => {
    localStorage.setItem(
      OFFERINGS_COLUMNS,
      preferences.visibleContent?.toString() ?? ''
    );
  }, [preferences.visibleContent]);

  // Save column preferences to localStorage
  useEffect(() => {
    localStorage.setItem(
      OFFERINGS_PAGE_SIZE,
      preferences.pageSize?.toString() ?? ''
    );
  }, [preferences.pageSize]);
  // const allHidden = services.reduce((o, serv) => ({...o, [serv.id]: false}), {});
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (queriedMocks) return;
    // if (!token) {
    //   console.error('No token to call API');
    //   return;
    // }
    if (!token) return console.warn('Token not present');
    setQueriedMocks(true);
    callApi(
      new ListOfferingsCommand({}),
      token
      // 'mockOfferingsData.json'
    )
      .then((res) => {
        // To test pagination locally:
        // setRows(res.offerings.concat(compost(res.offerings, 17)))
        // TODO: Update models to have `offerings` @required
        setRows(res.offerings);
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }, [queriedMocks, token]);
  useEffect(() => {
    console.log('rows', rows);
    setFilteredRows(
      rows.filter(({ offeringName, ripServiceId }) =>
        [offeringName, ripServiceId].some((str) =>
          str?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
        )
      )
    );
    setCurrentPage(1);
  }, [rows, filteringText]);
  const countPages = () =>
    Math.ceil(filteredRows.length / (preferences.pageSize ?? 20));
  const [totalPages, setTotalPages] = useState(countPages());
  useEffect(() => {
    // setItems(rows);
    setItems(getPage(currentPage, preferences.pageSize ?? 20));
  }, [preferences.pageSize, currentPage, orderedRows, totalPages]);
  useEffect(() => {
    setTotalPages(countPages());
  }, [preferences.pageSize, filteredRows]);
  useEffect(
    () => setOrderedRows(orderRows()),
    [filteredRows, sortingDescending, sortColumn]
  );
  return (
    <>
      <Table
        stickyColumns={{ first: 1, last: 0 }}
        variant='container'
        contentDensity='comfortable'
        filter={
          <TextFilter
            filteringPlaceholder='Find an offering'
            filteringText={filteringText}
            onChange={(e) => setFilteringText(e.detail.filteringText)}
          />
        }
        sortingColumn={sortColumn}
        onSortingChange={(e) => {
          setSortColumn(e.detail.sortingColumn);
          setSortingDescending(e.detail.isDescending ?? true);
        }}
        visibleColumns={preferences.visibleContent}
        header={
          <Header
            counter={`(${filteredRows.length})`}
            actions={<ExportOfferingList />}
          >
            Offerings
          </Header>
        }
        sortingDescending={sortingDescending}
        columnDefinitions={[
          // {
          //   id: 'controls',
          //   header: '',
          //   isRowHeader: false,
          //   cell: (serv) => (
          //     <Center>
          //       <SpaceBetween
          //         size={'s'}
          //         direction={'horizontal'}
          //         alignItems={'center'}
          //       >
          //         <Button
          //           href={`/Moniker/offerings/${serv.offeringId}`}
          //           variant={'icon'}
          //           iconName='edit'
          //         />
          //         <Button
          //           // href={ `/Moniker/offerings/${serv.offeringId}`}
          //           variant={'icon'}
          //           iconName='remove'
          //         />
          //       </SpaceBetween>
          //     </Center>
          //   ),
          // },
          ...offeringPropList.map(
            (prop): TableProps.ColumnDefinition<Offering> => ({
              id: prop,
              header: fieldTitles[prop],
              sortingField: prop,
              isRowHeader: prop === 'offeringName',
              cell: (serv: Offering) => makeOfferingOrDraftCell(serv, prop),
            })
          ),
        ]}
        items={items}
        preferences={
          <OfferingListCollectionPreferences
            fields={offeringPropList}
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
        pagination={
          <Pagination
            onPreviousPageClick={() => setCurrentPage(currentPage - 1)}
            currentPageIndex={currentPage}
            onNextPageClick={() => setCurrentPage(currentPage + 1)}
            pagesCount={totalPages}
            onChange={(e) => setCurrentPage(e.detail.currentPageIndex)}
          />
        }
      />
    </>
  );
};
