import { Partition } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField, Multiselect } from '@amzn/awsui-components-react';
import { ReactElement } from 'react';
import { FieldProps } from '../FieldProps';

const options = Object.entries(Partition).map(
  ([value, label]): { value: string; label: string } => ({
    value,
    label,
  })
);
export const RegionsField = (
  props: FieldProps<Partition[], true>
): ReactElement => (
  <FormField label='Partitions'>
    <Multiselect
      disabled={props.disabled}
      onChange={(e) =>
        props.onChange?.(
          e.detail.selectedOptions.map((o) => o.value as Partition)
        )
      }
      options={options}
      selectedOptions={options.filter((o) =>
        props.value.includes(o.value as Partition)
      )}
    />
  </FormField>
);
